<section class="" style="padding-bottom: 50px">
    <div class="serviceHeading">
       <div class="container">
          <div class="row">
             <div class="col-12 text-center">
                <h1 class="text-white">{{blogList.title}}</h1>
                <ul class="breadcrumb">
                     <li><a href="/">Home</a></li>
                     <li><a href="#">{{blogList.title}}</a></li>
                   </ul> 
             </div>
          </div>
       </div>
    </div>
 </section>
 <section class="aboutSlide"></section>
<section class="section  animate__animated animate__fadeIn animate__delay-1s" style="padding: 15px 0;">
    <!--blog-->
    <div class="container">
        <div class="row">
            <div class="col-9">
                <!-- <div class="author col-12">
                  
                    <span class="authordetails">
                        <img src="assets/img/item1.jpg">
                        VMR ca <br>
                        
                        <label>20th Jun 2021</label><br>
                    </span>
                </div>  -->
                <div class="blogdetails col-12">
                    <!-- <p>{{blogList.long_desc}}</p> -->
                    <!-- <h4 class="">{{data.title}}</h4> -->
                    <div class="mb-4"  innerHtml="{{blogList.long_desc}}"></div>
                </div> 
             </div> <!--blog wrapper -->
             <div class="col-3">
                <div class="enquire-form py-3 px-3 border-0 bg-white rounded" style="z-index: 0;">
                   <form action="#">
                      <h5 class="text-center mb-3">Get a Callback</h5>
                      <div class="form-group inputWithIcon inputIconBg" aria-hidden="true">
                         <input type="text" class="form-control" placeholder="Enter Name" id="name">
                         <i class="lni lni-user"></i>
                      </div>
                      <div class="form-group inputWithIcon inputIconBg" aria-hidden="true">
                         <input type="email" class="form-control" placeholder="Enter Email" id="email">
                         <i class="lni lni-envelope"></i>
                      </div>
                      <div class="form-group inputWithIcon inputIconBg" aria-hidden="true">
                         <input type="number" class="form-control" placeholder="Enter Phone" id="phone">
                         <i class="lni lni-phone"></i>
                      </div>
                      <div class="form-group">
                         <textarea class="form-control" rows="3" id="message" placeholder="Message"></textarea>
                      </div>
                      <div class="text-center">
                         <a href="#" type="submit" class="btn btn-sm orange2 text-white font-weight-bold w-100"><i class="fas fa-phone-alt"></i> Call Us</a>
                      </div>
                   </form>
                </div>
             </div>
         </div>
    </div>
</section>
<section class="section gray2 " style="padding: 100px 0;">
    <!--blog-->
    <div class="container">
       
        <div class="row">
            <div class="col text-left">
                <div class="mr-auto" style="background: linear-gradient(#3f96fd 0%, #3999ff 100%); width: 150px; height: 5px; margin-bottom: 20px;"></div>
                <h6 class="">Knowledge Base For You</h6>
                <h2 class="mb-4"><b>Related Blogs</b></h2>
            </div>
        </div>
         <div class="row">
            <div class="col-sm-4 col-4 rounded text-center ml-1 mx-auto">
                <a class="text-dark bg-white singleblog" href="">
                    <img src="assets/img/ca_services1.jpg" class="img-fluid">
                    <div class="blog_text">
                        <h4 class="">Blog Title</h4>
                        <p class="mb-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris</p>
                    </div>
                </a>
            </div> <!-- single blog -->
             <div class="col-sm-4 col-4 rounded text-center ml-1 mx-auto">
                <a class="text-dark bg-white singleblog" href="">
                    <img src="assets/img/ca_services1.jpg" class="img-fluid">
                    <div class="blog_text">
                        <h4 class="">Blog Title</h4>
                        <p class="mb-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris</p>
                    </div>
                </a>
            </div> <!-- single blog -->
             <div class="col-sm-4 col-4 rounded text-center ml-1 mx-auto">
                <a class="text-dark bg-white singleblog" href="">
                    <img src="assets/img/ca_services1.jpg" class="img-fluid">
                    <div class="blog_text">
                        <h4 class="">Blog Title</h4>
                        <p class="mb-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris</p>
                    </div>
                </a>
            </div> <!-- single blog -->
         </div>
    </div>
 </section>
 