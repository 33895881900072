<section class="" style="padding-bottom: 50px">
    <div class="aboutHeading" *ngFor="let p of pageList"  style="background-image: url('{{url}}images/page_image/{{p.image}}') ;">
       <div class="container">
          <div class="row">
             <div class="col-12 text-center">
               <!-- <img src="{{url}}images/page_image/{{p.image}}" alt="about page"> -->
                <h2 class="text-white">{{p.title}}</h2>
                <ul class="breadcrumb">
                     <li><a href="/">Home</a></li>
                     <li><a href="#">{{p.title}}</a></li>
                   </ul> 
             </div>
          </div>
       </div>
    </div>
 </section>
 <section class="aboutSlide"></section>
 <section class="section">
    <div style="margin-top: 3.5rem;">
       <div class="container">
          <div class="px-5">
          <div class="row">
             <div class="col-12 col-md-6">
                <div style="background: linear-gradient(#3f96fd 0%,#3999ff 100%);width: 150px;height: 5px;margin-bottom: 10px;">
                </div>
                <h2 class="mb-4"><b>We are</b> a Management Consultancy</h2>
                <div class="mb-5 text-justify">
                   <p>VMR ca is registered private limited in the name M/s VMR Corporate ca Private Limited as Management Consultancy Company duly approved by Institute of Chartered Accountants of India (ICAI), we are team of professionals like Chartered Accountants, Company Secretaries, Cost Accountants, Lawyers and Technical Consultants of industry specific having expertise in their profession and field  providing services to various clients from various industries. 
                   </p>
                   <p>
                   <!-- Nulla sed dignissim magna. Sed eros tortor, viverra nec dui at, viverra volutpat elit. Integer a dui odio. Nullam nisl elit, bibendum quis mattis at, facilisis nec neque. In at malesuada erat. Mauris in facilisis.Nulla sed dignissim magna. Sed eros tortor, viverra nec dui at, viverra volutpat elit. Integer a dui odio. Nullam nisl elit, bibendum quis mattis at, facilisis nec neque. In at malesuada erat. Mauris in facilisis.Nulla sed dignissim magna. Sed eros tortor, viverra nec dui at, viverra volutpat elit. Integer a dui odio. Nullam nisl elit, bibendum quis mattis at, facilisis nec neque. In at malesuada erat. Mauris in facilisis.  -->
                </p>
                </div>
                <a  [routerLink]="['/team']" routerLinkActive="active"  class="btn text-border border-primary">
                Meet Our Team</a>
             </div>
             <div class="col-12 col-md-6">
                <!-- <img src="assets/img/groupDiscuss.svg" class="img-fluid"> -->
                <img src="assets/img/undraw_Calculator_0evy.svg" class="img-fluid">
             </div>
          </div>
          </div>
       </div>
    </div>
 </section>
 <section class="section">
    <div class="whyVMR" style="margin-bottom: 5rem;">
       <div class="container">
          <div class="px-5">
          <div class="row">
             <div class="col-12">
                <div class="mr-auto" style="background: linear-gradient(#3f96fd 0%,#3999ff 100%);width: 150px;height: 5px;margin-bottom: 20px;">
                </div>
                <h2 class="mb-4">Why <b>VMR?</b></h2>
                <p class="mb-5 w-100 mr-auto">Our practice has professional approach to understand the client’s business, market place and key concerns, and deliver an informed, positive, practical and cost effective service. VMR ca has been set up to serve clients of stature and profile.  The office is staffed by a combination of recruits, experienced professionals trained with them and expert associates. In this way, control is maintained over methodology and quality while capitalising on local business knowledge and understand clients requirement to serve them with utmost quality.
 </p>
             </div>
             <div class="col-12 col-md-6 col-lg-4 text-center pt-4 px-2">
                <div class="text-dark bg-white py-5 px-4 yVMR" style="box-shadow: 0 5px 30px 0 rgba(167,167,167,.16);">
                   <div style="display: inline-block;position: relative;">
                      <img src="assets/img/services-shape.svg" class="shape1">
                      <img src="assets/img/services-shape-2.svg" class="shape2">
                      <i class="lni lni-cogs text-white" style="display:inline-block;position: absolute; left:50%; top:50%; transform: translate(-50%, -50%); font-size: 40px;"></i>
                      <!-- <img src="assets/img/settings-gears.svg" style="display:inline-block;position: absolute; left:50%; top:50%; transform: translate(-50%, -50%);"> -->
                   </div>
                   <h4 class="mt-3 mb-4">Understanding</h4>
                   <p class="mb-4">We believe that an in-depth understanding of each client’s business is essential to provide excellent services.  The operating style and requirements of the client’s organization are distinctive and demand professionals who understand and respect their corporate culture and business practices, and who can work effectively with them.
 </p>
                   <!-- <a href="#" style="text-decoration: none;">Learn More <i class="lni lni-chevron-right"></i></a> -->
                </div>
             </div>
             <div class="col-12 col-md-6 col-lg-4 text-center pt-4 px-2">
                <div class="text-dark bg-white py-5 px-4 yVMR" style="box-shadow: 0 5px 30px 0 rgba(167,167,167,.16);">
                   <div style="display: inline-block;position: relative;">
                      <img src="assets/img/services-shape.svg" class="shape1">
                      <img src="assets/img/services-shape-2.svg" class="shape2">
                      <i class="lni lni-star text-white" style="display:inline-block;position: absolute; left:50%; top:50%; transform: translate(-50%, -50%); font-size: 40px;"></i>
                      <!-- <img src="assets/img/star.svg" class="mb-4" style="display:inline-block;position: absolute; left:50%; top:50%; transform: translate(-50%,-50%);"> -->
                   </div>
                   <h4 class="mt-3 mb-4">Industry Expertise</h4>
                   <p class="mb-4">Our industry knowledge, achieved through continuous training and experience, is built on a foundation of technical excellence - a prerequisite for all our professional staff. Our industry specialization gives us a hands‑on experience of a variety of environments.
 </p>
                   <!-- <a href="#" style="text-decoration:none">Learn More <i class="lni lni-chevron-right"></i></a> -->
                </div>
             </div>
             <div class="col-12 col-md-6 col-lg-4 text-center pt-4 px-2 ">
                <div class="text-dark bg-white py-5 px-4 yVMR" style="box-shadow: 0 5px 30px 0 rgba(167,167,167,.16);">
                   <div style="display: inline-block;position: relative;">
                   <img src="assets/img/services-shape.svg" class="shape1">
                   <img src="assets/img/services-shape-2.svg" class="shape2">
                   <i class="lni lni-notepad text-white" style="display:inline-block;position: absolute; left:50%; top:50%; transform: translate(-50%, -50%); font-size: 40px;"></i>
                   <!-- <img src="assets/img/info.svg" class="mb-4" style="display:inline-block;position: absolute; left:50%; top:50%; transform: translate(-50%,-50%);"> -->
                </div>
                   <h4 class="mt-3 mb-4">Effective Communication</h4>
                   <p class="mb-4">Effective communication is fundamental to good working relationships. We listen to the client, and to their concerns, and reflect them in our approach.</p>
                   <!-- <a href="#" style="text-decoration:none">Learn More <i class="lni lni-chevron-right"></i></a> -->
                </div>
             </div>
             <div class="col-12 col-md-6 col-lg-4 text-center pt-4 px-2 ">
                <div class="text-dark bg-white py-5 px-4 yVMR" style="box-shadow: 0 5px 30px 0 rgba(167,167,167,.16);">
                   <div style="display: inline-block;position: relative;">
                   <img src="assets/img/services-shape.svg" class="shape1">
                   <img src="assets/img/services-shape-2.svg" class="shape2">
                   <i class="lni lni-investment text-white" style="display:inline-block;position: absolute; left:50%; top:50%; transform: translate(-50%, -50%); font-size: 40px;"></i>
                   <!-- <img src="assets/img/edit-list.svg" class="mb-4" style="display:inline-block;position: absolute; left:50%; top:50%; transform: translate(-50%,-50%);"> -->
                   </div>
                   <h4 class="mt-3  mb-4">Cost Effective</h4>
                   <p class="mb-4">As consultants, we help our clients to avoid duplication and minimize costs, and deliver services that are cost effective and add value to business.</p>
                   <!-- <a href="#" style="text-decoration:none">Learn More <i class="lni lni-chevron-right"></i></a> -->
                </div>
             </div>
             <div class="col-12 col-md-6 col-lg-4 text-center pt-4 px-2 ">
                <div class="text-dark bg-white py-5 px-4 yVMR" style="box-shadow: 0 5px 30px 0 rgba(167,167,167,.16);">
                   <div style="display: inline-block;position: relative;">
                   <img src="assets/img/services-shape.svg" class="shape1">
                   <img src="assets/img/services-shape-2.svg" class="shape2">
                   <i class="lni lni-investment text-white" style="display:inline-block;position: absolute; left:50%; top:50%; transform: translate(-50%, -50%); font-size: 40px;"></i>
                   <!-- <img src="assets/img/edit-list.svg" class="mb-4" style="display:inline-block;position: absolute; left:50%; top:50%; transform: translate(-50%,-50%);"> -->
                   </div>
                   <h4 class="mt-3  mb-4">Advisor</h4>
                   <p class="mb-4">Sound commercial advice is essential at all stages and, as consultants, we aim to provide this at all times.  Our approach is to highlight important issues, discuss them with the client, and provide practical advice on how threats, constraints and weaknesses can best be converted into opportunities and strengths.
 </p>
                   <!-- <a href="#" style="text-decoration:none">Learn More <i class="lni lni-chevron-right"></i></a> -->
                </div>
             </div>
             <div class="col-12 col-md-6 col-lg-4 text-center pt-4 px-2 ">
                <div class="text-dark bg-white py-5 px-4 yVMR" style="box-shadow: 0 5px 30px 0 rgba(167,167,167,.16);">
                   <div style="display: inline-block;position: relative;">
                   <img src="assets/img/services-shape.svg" class="shape1">
                   <img src="assets/img/services-shape-2.svg" class="shape2">
                   <i class="lni lni-investment text-white" style="display:inline-block;position: absolute; left:50%; top:50%; transform: translate(-50%, -50%); font-size: 40px;"></i>
                   <!-- <img src="assets/img/edit-list.svg" class="mb-4" style="display:inline-block;position: absolute; left:50%; top:50%; transform: translate(-50%,-50%);"> -->
                   </div>
                   <h4 class="mt-3  mb-4">Co-ordination & Continuity</h4>
                   <p class="mb-4">There is nothing more frustrating to a productive business relationship than having different points of contact with no coordination amongst them or continuity in serving.  It is our policy for business consulting, audit, tax and regulatory reporting services to assign a single lead partner who is responsible for all of our services to the client.</p>
                   <!-- <a href="#" style="text-decoration:none">Learn More <i class="lni lni-chevron-right"></i></a> -->
                </div>
             </div>                        
          </div>
       </div>
       </div>
    </div>
 </section>