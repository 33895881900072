<section class="" style="padding-bottom: 50px">
    <div class="aboutHeading" *ngFor="let p of pageList"   style="background-image: url('{{url}}images/page_image/{{p.image}}') ;">
       <div class="container">
          <div class="row">
             <div class="col-12 text-center">
                <h2 class="text-white">Contact Our Expert Team 
                  <!-- {{p.title}} -->
               </h2>
                <!-- <h2 class="text-white"></h2> -->
                       <!-- <img src="{{url}}images/page_image/{{p.image}}" alt="contact page"> -->
                <ul class="breadcrumb">
                     <li><a href="\">Home</a></li>
                     <li><a href="#">Contact Us</a></li>
                   </ul> 
             </div>
          </div>
       </div>
    </div>
 </section>
 <section class="aboutSlide"></section>
 <section class="section">
    <!-- <div style="margin-top: 5rem;"> -->
       <div class="container">
         <!--  <div class="contactHeading text-center mb-5">
             <h2>Contact Our Expert Team</h2>
             <h6>If you need our help, have questions about how to use the platform or are experiencing technical difficulties, please do not hesitate to contact us.</h6>
          </div> -->
          <style type="text/css">
             .pull-left{
                float: left;
             }
          </style>
          <div class="row mx-auto">
          <div class="contactForm col-lg-6">
             <form action="#">
                 <div class="form-group col-lg-6 pull-left">
                            <label for="nme">Name</label>
                            <input type="text" class="form-control" placeholder="Enter Name" id="nme">
                         </div>
                         <div class="form-group col-lg-6 pull-left">
                            <label for="email">Email address:</label>
                            <input type="email" class="form-control" placeholder="Enter Email" id="email">
                         </div>
                         <div class="form-group col-lg-6 pull-left">
                            <label for="companynme">Company Name</label>
                            <input type="text" class="form-control" placeholder="Company Name" id="companynme">
                         </div>
                         <div class="form-group col-lg-6 pull-left">
                            <label for="sel1">Location</label>
                            <input type="text" class="form-control" placeholder="Enter Location" id="sel1">
                            <!-- <select class="form-control" id="sel1">
                               <option>India</option>
                               <option>India</option>
                               <option>India</option>
                               <option>India</option>
                               <option>India</option>
                            </select> -->
                         </div>
                          <div class="form-group col-lg-12 float-left">
                            <label for="comment">Message</label>
                            <textarea class="form-control" rows="5" id="comment"></textarea>
                         </div>
                          <div class="form-group col-lg-12  float-left text-center">
                            <button type="submit" class="btn btn-primary">Submit</button>
                         </div>
                      <p>By submitting this form you agree to our terms and conditions and our Privacy Policy which explains how we may collect, use and disclose your personal information including to third parties.</p>
                
             </form>
          </div>
           <div class=" col-lg-6">
             <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3502.035767967496!2d77.07039531503622!3d28.62869008241925!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d04c397159cc5%3A0xf6328450aa655f7a!2sVMR%20CA!5e0!3m2!1sen!2sin!4v1623314660407!5m2!1sen!2sin" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
           </div>
       </div>
    </div>
 </section>
 <section class="section">
     <div style="margin-bottom: 10rem;">
         <div class="container">	
         <div class="row mx-auto text-center">
             <div class="col-4">
                 <div>
                     <i class="lni lni-envelope mb-2 f-50" style=""></i>
                     <h3 class="mb-3">Email us</h3>
                     <p>We are always reachable at mail, our team is actively checking requests on mail</p>
                     <a href="#">hello@vmrca.com</a>
                 </div>
             </div>
             <div class="col-4">
                 <div>
                     <i class="lni lni-phone mb-2 f-50" style=""></i>
                     <h3 class="mb-3">Call us</h3>
                     <p>We love to have a quick call to understand your requirements/queries</p>
                     <a href="tel:7428243651">+91-7428243651</a>
                 </div>
             </div>
             <div class="col-4">
                 <div>
                     <i class="lni lni-support mb-2 f-50" style=""></i>
                     <h3 class="mb-3">Reach Us</h3>
                     <p *ngFor="let p of pageList" >{{p.content}}</p>
                     <a target="_blank" href="https://www.google.com/maps/dir//VMR+CA,+B-43,+Second+Floor,+Shankar+Garden,+Vikaspuri,+Delhi,+110018/@28.6286901,77.0703953,17z/data=!4m9!4m8!1m0!1m5!1m1!1s0x390d04c397159cc5:0xf6328450aa655f7a!2m2!1d77.072584!2d28.6286889!3e0">View Directions</a>
                 </div>
             </div>
         </div>
         </div>
     </div>
 </section>