<!-- <ul class="nav">
    <li><a [routerLink]="['/home']" routerLinkActive="active">Home</a></li>
    <li><a [routerLink]="['/about']" routerLinkActive="active">About</a></li>
    <li><a [routerLink]="['/contact']" routerLinkActive="active">Contact</a></li>
    <li><a [routerLink]="['/checkout']" routerLinkActive="active">Checkout</a></li>


       

</ul>  -->
<section class="preheader">
   <div class="container">
      <div class="row py-2 text-white">
         <div class="col-12 d-none d-sm-block col-sm-3 col-md-2 text-center text-sm-left pb-3 pb-sm-0">
            <ul class="socialicons">
               <li class="pr-1">
                  <a href="https://twitter.com/VMR_CA"  target="_blank"><i class="fab fa-twitter"></i></a>
               </li>
               <li class="px-2">
                  <a href="https://www.linkedin.com/company/13263956/"  target="_blank"><i class="fab fa-linkedin"></i></a>
               </li>
               <li class="px-2">
                  <a href="https://www.facebook.com/VMRCo/" target="_blank"><i class="fab fa-facebook-f"></i></a>
               </li>
               <li class="pl-1">
                  <a href="https://www.youtube.com/channel/UCsbE5A9UQHVykJl_3TPY8QA" target="_blank"><i class="fab fa-youtube"></i></a>
               </li>
            </ul>
         </div>
         <div class="col-6 col-sm-3 col-md-3 text-right pr-0">
            &nbsp;
         </div>
         <div class="col-6 col-sm-5 col-md-5 pr-sm-0 form-group-sm">
            <ul class="top-headerul text-right">
               <li><a href="mailto:info@vmrCA.com" class="text-white tele">info@vmrCA.com</a></li>
                <li><a href="tel:+917428243651" class="text-white tele">+91-7428243651</a></li>
               <li *ngIf="!id"><a  data-toggle="modal" data-target="#loginmodel" class="text-white logIn">Login/Signup</a></li>
             
               <li *ngIf="id"><a [routerLink]="['/user-orders']" routerLinkActive="active"  class="text-white">My Account</a></li>
               <li *ngIf="id"><a (click)="logout();" href="#!"  class="text-white">Logout</a></li>
            </ul>
         </div>
         <div class="col-6 col-sm-3 col-md-2 pr-sm-0 form-group-sm d-none d-sm-block">
            <select class="form-control-sm" id="sel1">
               <option disabled selected>Branch Offices</option>
               <option *ngFor="let data of locationList">{{data.location_name}}</option>
               <!-- <option>Punjab</option>
               <option>UP</option>
               <option>Bihar</option>
               <option>Odisha</option> -->
            </select>
         </div>
         <!-- <div class="col-6 col-sm-3 col-md-2 pr-sm-0 form-group-sm">
            <a href="">
               Login
            </a>
         </div> -->
      </div>
   </div>
</section>
<!--SECTION 2-->
<nav>
   <div class="wrapper container">
      <div class="logo">
         <a href="/" routerLinkActive="active"><img src="assets/img/logo.png" width="105" /></a>
      </div>
      <input type="radio" name="slider" id="menu-btn" />
      <input type="radio" name="slider" id="close-btn" />
      <ul class="nav-links font1">
         <label for="close-btn" class="btn close-btn"><i class="fas fa-times"></i></label>
         <li>
            <a href="/" routerLinkActive="active" class="mainLinks desktop-item">Home</a>
            <input type="checkbox" id="showHome" />
            <label for="showHome" class="mobile-item">Home</label>
         </li>
         <li>
            <a [routerLink]="['/about']" routerLinkActive="active" class="mainLinks desktop-item">About</a>
            <input type="checkbox" id="showAbout" />
               <label for="showAbout" class="mobile-item">About</label>
         </li>
         <li>
            <a href="#" class="desktop-item mainLinks serviceItem">Services <i class="fas fa-caret-down"></i></a>
            <input type="checkbox" id="showMega" />
            <label for="showMega" class="mobile-item">Services <i class="fas fa-caret-right accor"></i></label>

            <div class="mega-box">
               <div class="content">
                  <div class="row mb-3">
                     <div class="col-lg-4">
                        <!-- <header>Audit and Assurance</header>
                        <header>Tax & Legal</header>
                        <header>Advisory</header>
                        <header>Business Services & Outsourcing</header> -->
                        <ul class="list-unstyled position-relative">
                           <ng-container *ngFor="let data of categoryList">
                           <li class="menuItem{{data.id}} menuItem marginZero" >
                              <a href="#" class="desktop-item">{{data.category_name}}</a>
                              <input type="checkbox" id="{{data.id}}" />
                              <label for="{{data.id}}" class="mobile-item pl-0 font17">{{data.category_name}} <i class="fas fa-caret-right"></i></label>
                              <div class="subMenu{{data.id}} subMenu customScrollBar">
                                 <ul class="mega-links text-uppercase col-lg-6 pull-left">
                                    <!-- routerLink="/vendor-order/{{vend._id}}" -->
                                    <!-- [routerLink]="['/service',data.slug]" -->
                                    <!-- routerLink="/service" [queryParams]="{'slug':serviceData.slug}" -->
                                    <li  *ngFor="let serviceData of data.service"><a  routerLink ="/service/{{serviceData.slug}}"   routerLinkActive="active" >{{serviceData.service_name}}</a></li>
                                    <!-- <li><a href="#">Dynamic Risk Assessment</a></li>
                                    <li><a href="#">Audit data & analytics</a></li>
                                    <li><a href="#">Accounting Research</a></li>
                                    <li><a href="#">Business reporting</a></li>
                                    <li><a href="#">Assurance</a></li>
                                    <li><a href="#">Risk Based Audit</a></li>
                                    <li><a href="#">Internal Audit & Control Review</a></li> -->
                                 </ul>
                                 <div class=" col-lg-6  pull-left">
                                    <img src="assets/img/about.svg" alt="" class="p-30">
                                 </div>
                              </div>
                           </li>
                        </ng-container>
                           <!-- <li class="menuItem2 menuItem marginZero">
                              <a href="#" class="desktop-item">Tax & Legal</a>
                              <input type="checkbox" id="showTax" />
                              <label for="showTax" class="mobile-item pl-0 font17">Tax & Legal <i class="fas fa-caret-right"></i></label>
                              <div class="subMenuTwo subMenu customScrollBar">
                              <ul class="mega-links">
                                 <li><a href="#">CROSS BORDER TAXATION</a></li>
                                 <li><a href="#">GLOBAL TAX SERVICES</a></li>
                                 <li><a href="#">TRANSFER PRICING</a></li>
                                 <li><a href="#">TRANSACTION TAX</a></li>
                                 <li><a href="#">TAX TECHNOLOGY</a></li>
                                 <li><a href="#">GLOBAL EXPATRIATE SERVICES</a></li>
                                 <li><a href="#">CUSTOMS AND INTERNATIONAL TRADE</a></li>
                                 <li><a href="#">INFORMATION EXCHANGE COMPLIANCES</a></li>
                                 <li><a href="#">GOODS AND SERVICES TAX (GST)</a></li>
                                 <li><a href="#">OTHER INDIRECT TAXES</a></li>
                                 <li><a href="#">TAX LITIGATION</a></li>
                                 <li><a href="#">TAX ADVISORY AND COMPLIANCE</a></li>
                                 <li><a href="#">REPRESENTATION AND LITIGATION SUPPORT</a></li>
                                 <li><a href="#">FEMA & FDI CONSULTATION AND COMPLIANCE</a></li>
                              </ul>
                           </div>
                           </li>
                           <li class="menuItem3 menuItem marginZero">
                              <a href="#" class="desktop-item">Advisory</a>
                              <input type="checkbox" id="showAdvisory" />
                              <label for="showAdvisory" class="mobile-item pl-0 font17">Advisory <i class="fas fa-caret-right"></i></label>
                              <div class="subMenuThree subMenu customScrollBar">
                                 <ul class="mega-links">
                                    <li><a href="#">BUSINESS RESTRUCTURING SERVICES</a></li>
                                    <li><a href="#">BUSINESS SETUP SERVICES</a></li>
                                    <li><a href="#">CORPORATE FINANCE</a></li>
                                    <li><a href="#">CYBER SECURITY AND IT GOVERNANCE</a></li>
                                    <li><a href="#">DIGITAL TRANSFORMATION</a></li>
                                    <li><a href="#">DUE DILIGENCE</a></li>
                                    <li><a href="#">MERGER & ACQUISITION</a></li>
                                    <li><a href="#">ENTERPRISE RESOURCE PLANNING (ERP)</a></li>
                                    <li><a href="#">FORENSIC AUDIT</a></li>
                                    <li><a href="#">GOVERNMENT ADVISORY</a></li>
                                    <li><a href="#">MANAGEMENT CONSULTING</a></li>
                                    <li><a href="#">REAL ESTATE AND CONSTRUCTION</a></li>
                                    <li><a href="#">RISK AND ADVISORY</a></li>
                                    <li><a href="#">VALUATIONS</a></li>
                                    <li><a href="#">VIRTUAL ANALYTICS</a></li>
                                    <li><a href="#">TRANSACTION ADVISORY</a></li>
                                    <li><a href="#">FRAUD INVESTIGATION</a></li>
                                 </ul>
                              </div>
                           </li>
                           <li class="menuItem4 menuItem marginZero">
                              <a href="#" class="desktop-item">Business Services & Outsourcing</a>
                              <input type="checkbox" id="showBusiness" />
                              <label for="showBusiness" class="mobile-item pl-0 font17">Business Services & Outsourcing <i class="fas fa-caret-right"></i></label>
                              <div class="subMenuFour subMenu customScrollBar">
                                 <ul class="mega-links">
                                    <li><a href="#">HUMAN RESOURCE OUTSOURCING</a></li>
                                    <li><a href="#">RESOURCE LENDING</a></li>
                                    <li><a href="#">HR RECRUITMENT OUTSOURCING</a></li>
                                    <li><a href="#">INTERNATIONAL BUSINESS</a></li>
                                    <li><a href="#">LEARNING SOLUTIONS</a></li>
                                    <li><a href="#">TRAINING</a></li>
                                    <li><a href="#">OFFSHORING</a></li>
                                    <li><a href="#">OUTSOURCING</a></li>
                                    <li><a href="#">REVENUE CYCLE MANAGEMENT</a></li>
                                    <li><a href="#">CASH FLOW MANAGEMENT</a></li>
                                    <li><a href="#">RECEIVABLES MANAGEMENT</a></li>
                                    <li><a href="#">TECHNOLOGY SERVICES</a></li>
                                 </ul>
                              </div>
                           </li> -->
                        </ul>
                     </div>
                     
                  </div>
               </div>
            </div>
         </li>
         <li>
            <a [routerLink]="['/industry']" routerLinkActive="active" class="desktop-item mainLinks">Industry</a>
            <input type="checkbox" id="showIndustry" />
            <label for="showIndustry" class="mobile-item">Industry</label>
         </li>
         <li>
            <a [routerLink]="['/team']" routerLinkActive="active" class="desktop-item mainLinks">Team</a>
            <input type="checkbox" id="showTeam" />
            <label for="showTeam" class="mobile-item">Team</label>
         </li>
         <li>
            <a [routerLink]="['/blog']" routerLinkActive="active"class="desktop-item mainLinks">Insights</a>
            <input type="checkbox" id="showBlog" />
            <label for="showBlog" class="mobile-item">Insights</label>
         </li>
         <li>
            <a [routerLink]="['/career']" routerLinkActive="active" class="desktop-item mainLinks">Career</a>
            <input type="checkbox" id="showCareer" />
            <label for="showCareer" class="mobile-item">Career</label>
         </li>
         <li>
            <a [routerLink]="['/contact']" routerLinkActive="active" class="desktop-item mainLinks">Contact Us</a>
            <input type="checkbox" id="showContact" />
            <label for="showContact" class="mobile-item">Contact Us</label>
         </li>
      </ul>
      <label for="menu-btn" class="btn menu-btn"><i class="fas fa-bars"></i></label>
   </div>
</nav>