import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
function _window() : any {
  // return the global native browser window object
  return window;
}
@Injectable({
  providedIn: 'root'
})
export class WebapiService {
  get nativeWindow() : any {
    return _window();
 }

 public url1 = 'https://vmrCA.com/admin/';
  private url = 'https://vmrCA.com/admin/App/';
  private imageUrl = 'https://vmrCA.com/admin/images/service_doc/'
  // private url = 'https://vmrCA.com/admin/App/';
  constructor(private http:HttpClient) { }

  isLogedIn(){
    return localStorage.getItem('loginId');
     
  }

  getUserData(id=""){
   return this.http.get(this.url+"getUser/"+id);
  }
  getCategory(id=""){
   return this.http.get(this.url+"getCategory/"+id);
  }
  getEnquiryById(id=""){
    return this.http.get(this.url+"getEnquiryById/"+id);
   }
   getOrderById(id=""){
    return this.http.get(this.url+"getOrderById/"+id);
   }
  getTeam(id=""){
    return this.http.get(this.url+"getTeam/"+id);
   }
   getIndustry(id=""){
    return this.http.get(this.url+"getIndustry/"+id);
   }
   
  
  getAllBlogs(id=""){
    return this.http.get(this.url+"getAllBlogs/"+id);
   }
   getBlogsByServiceId(id=""){
    return this.http.get(this.url+"getBlogsByServiceId/"+id);
   }
   getBlogsByIndustryId(id=""){
    return this.http.get(this.url+"getBlogsByIndustryId/"+id);
   }

  getCategoryBySlug(data :any){
    var form_data= new FormData();
    for(var key in data){
      form_data.append(key, data[key]);
    }
   return this.http.post(this.url+"getCategoryBySlug/",form_data);
  }
  getServices(id=""){
   
   return this.http.get(this.url+"getServices/"+id);
  }
  getAllServices(id=""){
   
    return this.http.get(this.url+"getAllServices/"+id);
   }
  getServiceBySlug(data:any){
    var form_data= new FormData();
    for(var key in data){
      form_data.append(key, data[key]);
    } 
    return this.http.post(this.url+"getServiceBySlug/",form_data);
   }
   doLogin(data:any){
    var form_data= new FormData();
    for(var key in data){
      form_data.append(key, data[key]);
    } 
    return this.http.post(this.url+"doLogin/",form_data);
  }
    insertUser(data:any){
      var form_data= new FormData();
      for(var key in data){
        form_data.append(key, data[key]);
      }
    return this.http.post(this.url+"insertUser/",form_data);
   }
   getIndustryBySlug(data:any){
    var form_data= new FormData();
    for(var key in data){
      form_data.append(key, data[key]);
    } 
    return this.http.post(this.url+"getIndustryBySlug/",form_data);
   }
   
  getSlider(id=""){
   
    return this.http.get(this.url+"getSlider/"+id);
   }
  
  deleteUser(id: string | number){
    //const url ='https://www.pahadilala.com/admin/App2/getUser';
   return this.http.get(this.url+"deleteUser/"+id);
  }
  insertFormData(data:any){
    var form_data= new FormData();
    for(var key in data){
      form_data.append(key, data[key]);
    } 
    return this.http.post(this.url+"insertUser/", form_data);
  }
  insertInquery(data:any){
    var form_data= new FormData();
    for(var key in data){
      form_data.append(key, data[key]);
    } 
    return this.http.post(this.url+"insertLead/", form_data);
  }
  submitQuote(data:any){
    var form_data= new FormData();
    for(var key in data){
      form_data.append(key, data[key]);
    } 
    return this.http.post(this.url+"insertLeads/", form_data);
  }
  
  getLocation(id=""){
   
    return this.http.get(this.url+"getLocation/"+id);
   }

   insertDoc(data:any){
    var form_data= new FormData();
  for(var key in data){
  form_data.append(key, data[key]);
} 
  return this.http.post(this.url+"insertDoc/",form_data);
 }

 getPage(id=""){
   
  return this.http.get(this.url+"getPage/"+id);
 }

 getCarrer(id=""){
   
  return this.http.get(this.url+"getCarrer/"+id);
 }

 insertContact(data:any){
  var form_data= new FormData();
  for(var key in data){
    form_data.append(key, data[key]);
  }
return this.http.post(this.url+"insertContact/",form_data);
}

getTeamBlogById(data:any){
  var form_data= new FormData();
  for(var key in data){
    form_data.append(key, data[key]);
  }
return this.http.post(this.url+"getTeamBlogById/",form_data);
}

insertApplication(data:any){
  var form_data= new FormData();
  for(var key in data){
    form_data.append(key, data[key]);
  }
return this.http.post(this.url+"insertApplication/",form_data);
}


   
}
